<template>
  <div>

    <div class="py-1">
      <router-link :to="{name:'PlayLotto'}" class="h5 font-weight-normal"><i class="fa fa-angle-left fa-sm"></i> เปลี่ยนหวย</router-link>
    </div>
    <div class="py-1 text-danger">
      เหลือเวลา {{roundTimer}}
    </div>
    <div class="row position-relative">
      <div class="col-xl-6 px-2 mb-4">

        <LottoInfo :round="round" @changePrice="changePrice" />
        <LottosetKeyNumbers
          v-if="page==='PageInput'"
          :round="round"
          :roundTimer="roundTimer"
          :ticket="ticket"
          @ticket="updateTicket"
        />
        <LottosetConfirm
          v-if="page==='PageTicket'"
          :round="round"
          :ticket="ticket"
          @cancel="cancel"
          @back="back"
          @success="success"
        />

        <loading
          :active="isLoading"
          :can-cancel="false"
          :is-full-page="false"
          background-color="#EBEDEF"
          :height="30"
          :width="30"
          color="#007bff"
        />
      </div>
      <div class="col-xl-6">
        <LottosetRecentResults :marketId="marketId" />
        <RecentTickets :reload="reloadTicket" @success="reloadTicket=false" />
      </div>
    </div>

    <SetsRatesModal :market="market" :round="cround" :rates="rates" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import LotteryService from '@/services/LotteryService'
import moment from '@/helpers/moment'
import cAlert from '@/helpers/alert'
import Swal from 'sweetalert2'

import LottoInfo from './components/LottoInfo'
import LottosetKeyNumbers from './components/LottosetKeyNumbers'
import LottosetConfirm from './components/LottosetConfirm'
import LottosetRecentResults from './components/LottosetRecentResults'
import RecentTickets from '@/views/PlayLotto/components/RecentTickets'
import SetsRatesModal from './components/SetsRatesModal'

export default {
  name: 'PlayLottosetRoundId',
  components: {
    LottoInfo,
    LottosetKeyNumbers,
    LottosetConfirm,
    LottosetRecentResults,
    RecentTickets,
    SetsRatesModal
  },
  data() {
    return {
      page: 'PageInput',
      isLoading: false,
      round: null,
      roundInterval: null,
      roundTimer: null,
      timerInterval: null,
      reloadTicket: false,
      ticket: {
        roundId: this.$route.params.roundId,
        rateId: this.$route.params.rateId,
        remarks: '',
        rows: [],
        uniqueCode: null
      },
      isShowModal: false
    }
  },
  computed: {
    roundId() {
      return this.$route.params.roundId
    },
    rateId() {
      return this.$route.params.rateId
    },
    marketId() {
      return this.round?.marketId
    },
    cround() {
      return {
        _id: this.round?._id,
        roundDate: this.round?.roundDate
      }
    },
    market() {
      return this.round?.market ?? {}
    },
    rates() {
      return this.round?.rates ?? []
    },
    isMobile() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Check for iOS devices
      if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return true;
      }

      // Check for Android devices
      if (/Android/i.test(userAgent)) {
        return true;
      }

      // Check for other mobile devices
      if (/BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
        return true;
      }

      return false;
    }
  },
  methods: {
    getRound(silent=false) {

      if(!silent)
        this.isLoading = true

      LotteryService.getLottosetRound(this.roundId, this.rateId)
      .then((response)=>{
        if(response.success) {
          this.round = response.data
          this.reloadTicket = true

          /**
           * นับเวลาถอยหลัง
           */
          if(this.round.market.marketType === 'single') {
            this.$router.push({name: 'PlayRoundId', params: { roundId: this.roundId }})
          }else
          if(!silent) {
            console.log('!silent')
            this.roundCountdown()
            this.timerInterval = setInterval(() => {
              this.roundCountdown()
            }, 1000)
          }
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        /**
         * error code 501 = ให้ redirect ไปหน้ารวมหวย
         */
        if(!silent || e?.status===501) {
          cAlert({
            ...e,
            title: 'ผิดพลาด!',
            text: e?.message || 'กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          }, ()=>{
            this.$router.push({name: 'Lottosets'})
          })
        }
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    roundCountdown() {
      try {
        if(this.round.status === 'Open') {
          if(moment().isBetween(this.round.roundDate.open, this.round.roundDate.close)) {

            const diff = moment(this.round.roundDate.close).diff(moment(), 'milliseconds')
            const duration = moment.duration(diff, 'milliseconds')
            let d = moment.duration(duration).days(),
                h = moment.duration(duration).hours(),
                m = moment.duration(duration).minutes(),
                s = moment.duration(duration).seconds()

            if(d > 0) h = (d*24) + h

            const texts = []
            if(h > 0) {
              if(h < 10)
                texts.push(`0${h}`)
              else
                texts.push(h)
            }

            if(h > 0 || m > 0) {
              if(m < 10)
                texts.push(`0${m}`)
              else
                texts.push(m)
            }

            if(s < 10)
              texts.push(`0${s}`)
            else
              texts.push(s)

            this.roundTimer = texts.join(':')
          }else{

            this.roundTimer = 'ปิดรับ'
            clearInterval(this.timerInterval)
            throw new Error()
          }
        }else{

          clearInterval(this.timerInterval)
          throw new Error()
        }
      }
      catch(e) {

        Swal.fire({
          text: e?.message || 'ปิดรับแล้ว!',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        .then(()=>{
          this.$router.push({name: 'Lottosets'})
        })
      }
    },
    updateTicket(ticket) {
      this.page = 'PageTicket'
      this.ticket = ticket
    },
    back() {
      this.page = 'PageInput'
    },
    clearData() {
      this.ticket.rows = []
      this.ticket.remarks = ''
    },
    cancel() {
      this.clearData()
      this.back()
    },
    success() {
      this.$notify({
        type: 'success',
        title: 'ส่งโพยแล้ว!',
        text: 'รอการตรวจสอบ...',
      })
      this.reloadTicket = true
      this.$store.dispatch('reloadBalance')
      this.clearData()
      this.back()
    },
    changePrice() {
      this.isShowModal = true
    },
    modalClosed() {
      this.isShowModal = false
    }
  },
  mounted() {
    this.getRound()
    this.roundInterval = setInterval(() => {
      this.getRound(true)
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.timerInterval)
    clearInterval(this.roundInterval)
  }
}
</script>
