<template>
  <div id="key-lottoset">
    <div class="card alert-primary border-primary mb-3">
      <div class="card-body p-3">
        <h4 class="text-center text-primary">ระบุตัวเลข 4 หลัก</h4>
        <div ref="inputNumbers" class="key-lottoset-numbers">
          <b-form-input
            class="form-control input-lottoset-number"
            maxlength="1"
            v-model="inputNumbers.a"
            v-on:keydown="keydown"
            inputmode="numeric"
            pattern="[0-9]*"
            no="a"
          />
          <b-form-input
            class="form-control input-lottoset-number"
            maxlength="1"
            v-model="inputNumbers.b"
            v-on:keydown="keydown"
            inputmode="numeric"
            pattern="[0-9]*"
            no="b"
          />
          <b-form-input
            class="form-control input-lottoset-number"
            maxlength="1"
            v-model="inputNumbers.c"
            v-on:keydown="keydown"
            inputmode="numeric"
            pattern="[0-9]*"
            no="c"
          />
          <b-form-input
            class="form-control input-lottoset-number"
            maxlength="1"
            v-model="inputNumbers.d"
            v-on:keydown="keydown"
            inputmode="numeric"
            pattern="[0-9]*"
            no="d"
          />
          <span class="key-sign text-secondary">
            <i class="fas fa-equals"></i>
          </span>
          <b-form-input
            class="form-control input-lottoset-number key-qty"
            maxlength="2"
            v-model="keyQty"
            placeholder="1"
            v-on:keydown="keydown"
            inputmode="numeric"
            pattern="[0-9]*"
            no="qty"
          />
        </div>

        <div v-if="isMobile" class="mt-4 mobile-keyboard">
          <table class="table mb-0">
            <tr>
              <td width="25%">
                <button class="btn btn-info" @click="clickBtn('1')">1</button>
              </td>
              <td width="25%">
                <button class="btn btn-info" @click="clickBtn('2')">2</button>
              </td>
              <td width="25%">
                <button class="btn btn-info" @click="clickBtn('3')">3</button>
              </td>
              <td width="25%">
                <button class="btn btn-danger" @click="clickBtn('Backspace')">
                  <i class="fas fa-backspace"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td width="25%">
                <button class="btn btn-info" @click="clickBtn('4')">4</button>
              </td>
              <td width="25%">
                <button class="btn btn-info" @click="clickBtn('5')">5</button>
              </td>
              <td width="25%">
                <button class="btn btn-info" @click="clickBtn('6')">6</button>
              </td>
              <td rowspan="2" width="25%">
                  <button class="btn btn-primary" @click="clickBtn('Enter')">
                    OK
                  </button>
              </td>
            </tr>
            <tr>
              <td width="25%">
                <button class="btn btn-info" @click="clickBtn('7')">7</button>
              </td>
              <td width="25%">
                <button class="btn btn-info" @click="clickBtn('8')">8</button>
              </td>
              <td width="25%">
                <button class="btn btn-info" @click="clickBtn('9')">9</button>
              </td>
            </tr>
            <tr>
              <td width="25%"></td>
              <td width="25%">
                <button class="btn btn-info" @click="clickBtn('0')">0</button>
              </td>
              <td width="25%"></td>
              <td rowspan="2" width="25%"></td>
            </tr>
          </table>
        </div>

        <div class="mt-4" v-if="ticket.rows.length">
          <table class="table table-hover table-lottoset-numbers mb-0">
            <thead>
              <tr>
                <th class="text-center" width="40%">ชุดเลข</th>
                <th class="text-left" width="25%">จำนวนชุด</th>
                <th class="text-left">รวม</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in ticket.rows" :key="index">
                <td class="text-center text-primary" style="font-size: 120%; font-weight: 600;">
                  <DisplaySetsNumber :data="item.number" />
                </td>
                <td class="text-right">
                  <b-form-input
                    size="sm"
                    v-model="item.qty"
                    :number="true"
                    v-on:keydown="keynumber"
                    style="width: 60px"
                  />
                </td>
                <td class="text-left">{{ (item.price * item.qty) | amountFormat}}</td>
                <td class="text-right">
                  <span class="btn-delete" style="cursor: pointer;" @click="toggleRemoveBill(index)">
                    <i class="far fa-trash-alt text-danger bigger-175"></i>
                  </span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="text-info text-center">กรอกเท่ากัน</td>
                <td>
                  <b-form-input
                    size="sm"
                    v-model="inputQty"
                    :number="true"
                    v-on:keydown="keynumber"
                    style="width: 60px"
                  />
                </td>
                <td colspan="2"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col">
        <div class="d-flex justify-content-center mb-2">
          <label for="inlineFormCustomSelectPref" class="my-1 mr-2">หมายเหตุ:</label>
          <input type="text" class="form-control" v-model="ticket.remarks">
        </div>
      </div>
      <div v-if="roundIcon" class="col-auto">
        <div class="text-right">
          <img :src="roundIcon" height="35" class="shadow-sm float-right">
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <!-- <div class="col-2"></div> -->
      <div class="col">
        <h6 class="text-danger text-center pt-2">
          <span>เหลือเวลา</span>
          <span> {{roundTimer}}</span>
        </h6>
        <h5 class="text-center">
          <span> [{{roundMarketName}}]</span>
          <span> {{roundDate}}</span>
        </h5>
        <h4 class="text-center">รวม <span class="text-primary">{{ticketAmount | amountFormat(2, '0.00')}}</span> บาท</h4>
      </div>
      <!-- <div class="col-2">
        <h6 class="text-danger text-right time-right">
          <span>เหลือเวลา</span>
          <span> {{roundTimer}}</span>
        </h6>
      </div> -->
    </div>

    <div class="row justify-content-center">
      <div class="col-auto">
        <button class="btn btn-danger" @click="toggleClearBills">ล้างตาราง</button>
      </div>
      <div class="col-auto">
        <button class="btn btn-primary" @click="preSaveTicket">บันทึก</button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import Swal from 'sweetalert2'
import cryptoRandomString from 'crypto-random-string'
import DisplaySetsNumber from './DisplaySetsNumber'

export default {
  name: 'LottosetKeyNumbers',
  props: ['round', 'roundTimer', 'ticket'],
  components: {
    DisplaySetsNumber
  },
  data() {
    return {
      elements: null,
      inputNumbers: {
        a: '',
        b: '',
        c: '',
        d: ''
      },
      keyQty: '',
      inputQty: '',
      focus: null
    }
  },
  computed: {
    price() {
      if(!this.round)
        return 0

      return this.round?.rate?.price
    },
    discount() {
      if(!this.round)
        return 0

      return this.round?.rate?.discount
    },
    roundMarketName() {
      if(!this.round)
        return ''

      return this.round?.note.marketTitle
    },
    roundDate() {
      if(!this.round)
        return ''

      return moment(this.round.roundDate.date).format("DD-MM-YYYY")
    },
    roundIcon() {
      if(!this.round)
        return false

      return this.round?.market?.imageIcon || false
    },
    fullnumbers() {
      return `${this.inputNumbers.a}${this.inputNumbers.b}${this.inputNumbers.c}${this.inputNumbers.d}`
    },
    clickPos() {
      return this.fullnumbers.length
    },
    ticketAmount() {
      return this.ticket.rows.reduce((total, item) => {
        total += (item.price * item.qty)
        return total
      }, 0)
    },
    pos() {
      return {
        a: 0,
        b: 1,
        c: 2,
        d: 3,
        qty: 4
      }[this.focus] ?? 0
    },
    isMobile() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Check for iOS devices
      if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return true;
      }

      // Check for Android devices
      if (/Android/i.test(userAgent)) {
        return true;
      }

      // Check for other mobile devices
      if (/BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
        return true;
      }

      return false;
    }
  },
  watch: {
    inputQty(n, o) {
      if(n > 0) {
        this.ticket.rows = this.ticket.rows.map((item)=>{
          item.qty = n
          return item
        })
      }
    }
  },
  methods: {
    keydown(event) {
      if(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)) {
        if(this.pos < 4) {
          this.inputNumbers[this.focus] = event.key

          const nextPos = this.pos+1
          this.elements[nextPos<=4?nextPos:4].focus()
        }else{
          if(this.keyQty.length>=2) {
            this.keyQty = this.keyQty.slice(0, -1)
          }
          this.keyQty = `${this.keyQty}${event.key}`

          /**
           * ทำงานเมื่อเข้ามาจากมือถือ
           */
          if(this.isMobile && this.fullnumbers.length === 4) {
            const qty = parseInt(this.keyQty || 1)
            if(qty > 0) {
              this.elements[0].focus()
              setTimeout(()=>{
                this.addNumbers(this.fullnumbers, qty)
                this.keyQty = ''
              }, 50)
            }
          }else{
            const nextPos = this.pos+1
            this.elements[nextPos<=4?nextPos:4].focus()
          }
        }
      }else
      if(event.key === 'Backspace') {
        if(this.focus==='qty' && this.keyQty) {
          this.keyQty = this.keyQty.slice(0, -1)
          this.elements[4].focus()
        }else
        if(this.inputNumbers?.[this.focus]) {
          this.inputNumbers[this.focus] = ''
        }else{
          const prevPos = this.pos-1
          this.elements[prevPos>=0?prevPos:0].focus()
        }
      }else
      if(event.key === 'ArrowRight') {
        const nextPos = this.pos+1
        this.elements[nextPos<=4?nextPos:4].focus()
      }else
      if(event.key === 'ArrowLeft') {
        const prevPos = this.pos-1
        this.elements[prevPos>=0?prevPos:0].focus()
      }else
      if(this.pos === 4 && event.key === 'Enter') {
        if(this.fullnumbers.length === 4) {
          const qty = parseInt(this.keyQty || 1)
          if(qty > 0) {
            this.elements[0].focus()
            setTimeout(()=>{
              this.addNumbers(this.fullnumbers, qty)
              this.keyQty = ''
            }, 50)
          }
        }
      }

      event.preventDefault()
    },
    clickBtn(n) {
      this.keydown({
        key: n,
        preventDefault:()=>{}
      })
    },
    addNumbers(number, qty=1) {
      this.ticket.rows.push({
        type: 'lottoset',
        text: 'เลขชุด',
        number: number,
        qty: qty,
        price: this.price
      })

      this.inputNumbers = {
        a: '',
        b: '',
        c: '',
        d: ''
      }
    },
    toggleRemoveBill(index) {
      this.ticket.rows.splice(index, 1)
      this.elements[0].focus()
    },
    toggleClearBills() {
      this.inputNumbers = {
        a: '',
        b: '',
        c: '',
        d: ''
      }
      this.ticket.rows = []
      this.ticket.remarks = ''
      this.elements[0].focus()
    },
    preSaveTicket() {
      this.ticket.rows = this.ticket.rows.filter((r)=>{
        return r.qty > 0
      })

      if(!this.ticket.rows.length) {
        this.elements[0].focus()
        return Swal.fire({
          text: 'กรุณากรอกชุดตัวเลข',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      this.ticket.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      this.$emit('ticket', this.ticket)
    },
    keynumber(event) {
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab'].includes(event.key)) {
        event.preventDefault()
      }
    },
    handleFocus(event) {
      this.focus = event?.target?.attributes?.no?.value ?? null
    }
  },
  mounted() {
    this.elements = this.$refs.inputNumbers.getElementsByClassName('input-lottoset-number')
    if(this.elements) {
      this.elements.forEach(input => {
        input.addEventListener('focus', this.handleFocus)
      })

      this.elements[0].focus()
    }
  },
  beforeDestroy() {
    if(this.elements) {
      this.elements.forEach(input => {
        input.removeEventListener('focus', this.handleFocus)
      })
    }
  }
}
</script>
<style lang="scss">
#key-lottoset {
  .key-lottoset-numbers {
    display: flex;
    justify-content: center;
    align-items: center;

    .input-lottoset-number {
      width: 55px;
      height: 55px;
      padding: 0px;
      margin: 0 4px;
      line-height: 1;
      font-size: 50px;
      text-align: center;

      &.key-qty {
        color: #0069D9;
        width: 70px;
      }
    }

    .input-lottoset-number::placeholder {
      color: #CCCCCC;
    }

    .key-sign {
      font-size: 150%;
    }
  }

  .mobile-keyboard {

    .table {
      tr {
        td {
          border-width: 0;
          padding: 5px;

          button {
            font-size: 150%;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .table-lottoset-numbers {
    background-color: #FFFFFF;
    thead {
      tr {
        th {
          font-weight: normal;
        }
      }
    }
    tbody, tfoot {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
