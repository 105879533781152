<template>
  <span class="disp-sets-number">
    <span v-for="item in items">{{ item }}</span>
  </span>
</template>
<script>
export default {
  name: 'DisplaySetsNumber',
  props: ['data'],
  computed: {
    items() {
      if(!this.data)
        return []

      return this.data.slice('')
    }
  }
}
</script>
<style lang="scss" scoped>
.disp-sets-number {
  span {
    display: inline-block;
    width: 15px;
    text-align: center;
  }
}
</style>
